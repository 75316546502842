import React from 'react'
import { graphql } from 'gatsby'

import enMessages from '../i18n/accommodation-k.en.json'
import plMessages from '../i18n/accommodation-k.pl.json'
import itMessages from '../i18n/accommodation-k.it.json'
import Layout from '../components/layout'
import SingleHeader from '../components/single-header'
import Footer from '../components/footer'

const messages = {
  en: enMessages,
  pl: plMessages,
  it: itMessages,
}

const AccommodationKasprzakPage = ({ pageContext: { locale }, data }) => (
  <Layout locale={locale}>
    <SingleHeader
      locale={locale}
      img={data.locationImage}
      title={messages[locale].title}
    />

    <div className="page_content">
      <div className="full_width_centered">
        <p dangerouslySetInnerHTML={{ __html: messages[locale].description }} />
        <p dangerouslySetInnerHTML={{ __html: messages[locale].bus }} />
        <p dangerouslySetInnerHTML={{ __html: messages[locale].address }} />

        <h2>Hotel Kasprzak</h2>

        <div id="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9956.82695799699!2d17.6671023!3d51.3992559!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x44d79ff179ebc648!2sHotel+Kasprzak!5e0!3m2!1sen!2sie!4v1554247964987!5m2!1sen!2sie"
            width="100%"
            height="450"
            frameBorder="0"
            style={{ saturation: '-90', border: 0 }}
            allowFullScreen
          />
        </div>
      </div>
    </div>
    <Footer locale={locale} />
  </Layout>
)

export default AccommodationKasprzakPage

export const pageQuery = graphql`
  query {
    locationImage: file(
      relativePath: { eq: "kasprzak.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, maxHeight: 369, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
